<template>
	<div class="error404">
		<Error404 />
	</div>
</template>

<script>
import Error404 from "components/extra/error404";

export default {
	components: {
		Error404,
	},
};
</script>
